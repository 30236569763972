.flagged-cell {
  background-color: #FFCCCB; /* Light red background */
  color: #FF0000; /* Red text */
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.calculated-column {
  background-color: #E6F3FF !important; /* Light blue background */
}

/* Ensure the header text is visible on the light blue background */
.calculated-column .ag-header-cell-text {
  color: #000000; /* Black text */
}

/* ... existing styles ... */

.wrap-text-header .ag-header-cell-label {
  white-space: normal !important;
  line-height: 1.2;
}

.wrap-text-header .ag-header-cell-label .ag-header-cell-text {
  overflow: visible;
  text-overflow: clip;
  white-space: normal !important;
}

.ag-header-cell {
  padding-left: 4px;
  padding-right: 4px;
}

.ag-header-cell-label {
  justify-content: flex-start; /* Change from center to flex-start */
}

.ag-header-cell-text {
  width: 100%;
  text-align: left; /* Change from center to left */
}

/* Add this new style to ensure left alignment */
.ag-header-cell-comp-wrapper {
  margin-left: 0 !important;
}

/* Style for the column resize handle */
.ag-theme-material .ag-header-cell-resize::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: col-resize;
}

/* Highlight the resize handle on hover */
.ag-theme-material .ag-header-cell-resize:hover::after {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Ensure the resize handle is visible */
.ag-theme-material .ag-header-cell {
  overflow: visible;
}

/* Styles for the expanded table view */
.data-merging-container.expanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  padding: 20px;
  overflow: auto;
}

/* Style for the expand/exit button in expanded view */
.data-merging-container.expanded .expand-toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
}

/* Optionally, adjust the z-index or other properties as needed */

/* ... existing styles ... */

/* Style for the resolve checkbox to appear as a simple square */
.resolve-checkbox {
  border: 2px solid #000; /* {{ edit_1 }} Changed to single solid border for a simpler thick box */
  border-radius: 0; /* {{ edit_2 }} Removed border-radius for a perfect square appearance */
  width: 24px;
  height: 24px;
  background-color: #fff;
  box-shadow: none; /* {{ edit_3 }} Removed any box-shadow to eliminate inner square/circle */
}

/* Optional: Simplify the checked state appearance */
.resolve-checkbox.Mui-checked {
  background-color: #4caf50; /* Green background when checked */
  border-color: #4caf50; /* Match border color with background */
  box-shadow: none; /* {{ edit_4 }} Ensure no shadow appears when checked */
}

/* Style for the Rationale column */
.ag-theme-material .ag-cell.ag-cell-rationale {
  padding-left: 10px;
}

/* Ensure the Rationale header is styled appropriately */
.ag-theme-material .ag-header-cell-rationale {
  background-color: #f0f0f0;
}

/* Yellow header for Preliminary PiN and Final PiN columns */
.yellow-header {
  background-color: yellow !important;
}